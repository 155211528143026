import { CapacitorConfig } from '@capacitor/cli';

const config: CapacitorConfig = {
  appId: 'com.sidedrawer.app', // NOTE For ios 'com.app.sidedrawer'
  appName: 'sdrw-ng-mobile-2',
  webDir: 'dist/sdrw-ng-mobile/browser',
  server: {
    hostname: '127.0.0.1',
    //url: 'https://192.168.68.101:12000', // NOTE uncomment this line to use the live server for development
    cleartext: true,
  },
  loggingBehavior: 'none',
  plugins: {
    SplashScreen: {
      launchShowDuration: 1,
      // launchAutoHide: true,
      // launchFadeOutDuration: 3000,
      backgroundColor: '#FFFFFF',
      // androidSplashResourceName: "splash",
      // androidScaleType: "CENTER_INSIDE",
      showSpinner: false,
      androidSpinnerStyle: 'small',
      iosSpinnerStyle: 'small',
      // spinnerColor: "#FF9500",
      splashFullScreen: false,
      splashImmersive: false,
      // layoutName: "launch_screen",
      // useDialog: false,
    },
  },
  // android: {
  //   webContentsDebuggingEnabled: true
  // },
  // ios: {
  //   appendUserAgent: "ios:application",
  //   webContentsDebuggingEnabled: true
  // }
};

export default config;
