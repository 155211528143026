import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { ShareFileWithSdActions } from './share-file-with-sd.store';
import { take, tap } from 'rxjs/operators';
import {
  LaunchStartFlow,
  StartFlowActionsTypes,
} from '../../../../start-flow/store/start-flow.actions';
import { AppState } from '../../../../reducers';
import { Store } from '@ngrx/store';
import { forkJoin, from, switchMap } from 'rxjs';
import { IosShareExtensionDataPluginService } from '../services/ios-share-sheet-plugin.service';
import { CoreRoutes } from '../../../../core/routes/core.routes';
import { FilesRoutes } from '../../../../files/routes/files.routes';
import { Router } from '@angular/router';
import { AndroidSharedFile } from '../services/android-share-sheet-plugin.service';
import { PreferencesService } from '../../biometrics/services/preferences.service';

@Injectable()
export class ShareFileWithSdEffects {
  constructor(
    private readonly actions: Actions,
    private readonly store: Store<AppState>,
    private readonly iosShareExtensionDataPluginService: IosShareExtensionDataPluginService,
    private readonly router: Router,
    private readonly preferences: PreferencesService
  ) {}

  startFlowCompleted$ = createEffect(
    () =>
      this.actions.pipe(
        ofType<LaunchStartFlow>(StartFlowActionsTypes.StartFlowCompleted),
        switchMap(() =>
          forkJoin([
            from(this.iosShareExtensionDataPluginService.read()),
            this.preferences.getShareFiles(),
          ])
        ),
        tap(([iosShareData, result]) => {
          const shareFiles: AndroidSharedFile[] = Object.values(
            JSON.parse(result?.value ?? '{}')
          );
          console.log({ shareFiles, iosShareData });
          if (shareFiles?.length > 0) {
            this.store.dispatch(
              ShareFileWithSdActions.sharedFilesReceived({
                shareFiles,
              })
            );
          }
          this.preferences.removeShareFiles().pipe(take(1)).subscribe();
          if (iosShareData?.length > 0) {
            this.store.dispatch(
              ShareFileWithSdActions.sharedFilesReceived({
                shareFiles: [
                  ...iosShareData?.map(sharedFile => ({
                    fileName: sharedFile.fileName,
                    fileSize: sharedFile.fileSize,
                    uri: sharedFile.url,
                  })),
                ],
              })
            );
            this.iosShareExtensionDataPluginService.clear();
          }
          if (shareFiles?.length > 0 || iosShareData?.length > 0) {
            this.router.navigateByUrl(
              `/${CoreRoutes.root}/${FilesRoutes.shareWithSd}`
            );
            return;
          }
        })
      ),
    { dispatch: false }
  );
}
