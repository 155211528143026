import { Injectable } from '@angular/core';

import { from, Observable } from 'rxjs';
import { GetResult, Preferences } from '@capacitor/preferences';
import { BiometricSecurityType } from '../store/biometrics.store';
import { map } from 'rxjs/operators';

@Injectable()
export class PreferencesService {
  clearAllInformation(): Observable<void> {
    return from(Preferences.clear());
  }

  getBiometricsPreferences(): Observable<GetResult> {
    return from(
      Preferences.get({
        key: 'biometrics',
      })
    );
  }

  setBiometricsPreferences(
    biometricSecurity: BiometricSecurityType
  ): Observable<void> {
    return from(
      Preferences.set({
        key: 'biometrics',
        value: biometricSecurity,
      })
    );
  }

  removeBiometricsPreferences(): Observable<void> {
    return from(
      Preferences.remove({
        key: 'biometrics',
      })
    );
  }

  haveAlreadyLoggedInDevice(): Observable<GetResult> {
    return from(
      Preferences.get({
        key: 'loggedInDevice',
      })
    );
  }

  removeLoggedInDevice(): Observable<void> {
    return from(
      Preferences.remove({
        key: 'loggedInDevice',
      })
    );
  }

  setFirstDeviceLogin(): Observable<void> {
    return from(
      Preferences.set({
        key: 'loggedInDevice',
        value: 'true',
      })
    );
  }

  setLogOut(): Observable<void> {
    return from(Preferences.set({ key: 'LogOut', value: 'true' }));
  }

  removeLogOut(): Observable<void> {
    return from(Preferences.remove({ key: 'LogOut' }));
  }

  getLogOut(): Observable<string> {
    return from(Preferences.get({ key: 'LogOut' })).pipe(
      map(({ value }) => value)
    );
  }

  setOpenId(openId: string): Observable<void> {
    return from(Preferences.set({ key: 'openId', value: openId }));
  }

  getOpenId(): Observable<string> {
    return from(Preferences.get({ key: 'openId' })).pipe(
      map(({ value }) => value)
    );
  }

  getShareFiles(): Observable<GetResult> {
    return from(Preferences.get({ key: 'shareFiles' }));
  }

  removeShareFiles(): Observable<void> {
    return from(Preferences.remove({ key: 'shareFiles' }));
  }
}
