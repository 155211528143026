import { NgModule } from '@angular/core';
import {
  PreloadAllModules,
  RouterModule,
  Routes,
  TitleStrategy,
} from '@angular/router';
import { SdPageTitleStrategy } from 'src/app/core/providers/sd-page-title-strategy.provider';
import { MobileCoreRoutes } from './core/routes/mobile-core.routes';
import { MobileAuthRoutes } from './auth/routes/auth.routes';
import { MobileVersionReviewRoutesEnum } from './version-review/routes/mobile-version-review-routes.enum';
import { AuthGuard } from '../../auth/guards/auth.guard';

const routes: Routes = [
  {
    path: MobileAuthRoutes.root,
    loadChildren: () =>
      import('./auth/mobile-auth.module').then(m => m.MobileAuthModule),
  },
  {
    path: MobileCoreRoutes.root,
    loadChildren: () =>
      import('./core/mobile-core.module').then(m => m.MobileCoreModule),
    canActivate: [AuthGuard],
  },
  {
    path: MobileVersionReviewRoutesEnum.root,
    loadChildren: () => import('./version-review/routes/version-review.routes'),
  },
  {
    path: '**',
    redirectTo: 'auth/authorize',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
  providers: [
    {
      provide: TitleStrategy,
      useClass: SdPageTitleStrategy,
    },
  ],
})
export class MobileAppRoutingModule {}
