import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from '../../environments/environment';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';

export interface AppState {
  router: RouterReducerState<any>;
}

export const reducers: ActionReducerMap<AppState> = {
  router: routerReducer,
};

export const metaReducers: MetaReducer<AppState>[] = !environment.production
  ? []
  : [];
// MetaReducer to clear the store on LogOut
// export function logoutMetaReducer(reducer) {
//   return function (state, action) {
//     console.log(
//       `From logoutMetaReducer ${action.type} - ${AuthActionsTypes.Logout}`
//     );
//     if (action.type === AuthActionsTypes.Logout) {
//       state = undefined;
//     }
//     return reducer(state, action);
//   };
// }
