import { Actions, createEffect, ofType } from '@ngrx/effects';
import { inject } from '@angular/core';
import {
  BiometricsActions,
  BiometricSecurityType,
  biometricsSecuritySelector,
} from './biometrics.store';
import { BiometricsService } from '../services/biometrics.service';
import {
  catchError,
  exhaustMap,
  map,
  mergeMap,
  retry,
  take,
  tap,
} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../reducers';
import { forkJoin, of, timer } from 'rxjs';
import { PreferencesService } from '../services/preferences.service';
import {
  AuthActionsTypes,
  AuthorizeUser,
  LogIn,
  LogOut,
  RefreshTokenRequired,
} from 'src/app/auth/store/auth.actions';
import { MobileAuthService } from '../../auth/services/mobile-auth.service';
import { LaunchStartFlow } from '../../../../start-flow/store/start-flow.actions';
import { AuthUser } from '../../../../auth/models/auth-user.model';

export const biometricInitialization$ = createEffect(
  () => {
    const actions$ = inject(Actions);
    const preferencesService = inject(PreferencesService);
    const biometricService = inject(BiometricsService);
    const store = inject(Store<AppState>);

    return actions$.pipe(
      ofType(BiometricsActions.initializeBiometricsStore),
      tap(() =>
        store.dispatch(
          BiometricsActions.gettingBiometrics({ gettingBiometrics: true })
        )
      ),
      exhaustMap(() =>
        forkJoin([
          preferencesService.getBiometricsPreferences().pipe(
            take(1),
            map(({ value }) => value)
          ),
          biometricService.checkBiometrics().pipe(take(1)),
          preferencesService.getLogOut().pipe(take(1)),
        ])
      ),
      map(([savedBiometricsSettings, { isAvailable }, LogOutSettings]) => {
        //console.log({ savedBiometricsSettings, isAvailable, LogOutSettings });
        if (
          !isAvailable ||
          savedBiometricsSettings !== BiometricSecurityType.on
        ) {
          store.dispatch(
            BiometricsActions.setBiometricSecurity({
              biometricSecurity: BiometricSecurityType.off,
            })
          );
          return new AuthorizeUser();
        }
        if (LogOutSettings) {
          return new AuthorizeUser();
        }
        store.dispatch(
          BiometricsActions.setBiometricSecurity({
            biometricSecurity: BiometricSecurityType.on,
          })
        );
        return BiometricsActions.initialAuthentication();
      }),
      catchError(error => {
        console.error({ error });
        store.dispatch(
          BiometricsActions.setBiometricSecurity({
            biometricSecurity: BiometricSecurityType.off,
          })
        );
        return of(new AuthorizeUser());
      }),
      tap(() =>
        store.dispatch(
          BiometricsActions.gettingBiometrics({ gettingBiometrics: false })
        )
      )
    );
  },
  { functional: true }
);

export const initialAuthentication$ = createEffect(
  () => {
    const actions$ = inject(Actions);
    const store = inject(Store<AppState>);
    const service = inject(BiometricsService);
    const mobileAuthService = inject(MobileAuthService);
    return actions$.pipe(
      ofType(BiometricsActions.initialAuthentication),
      mergeMap(() =>
        service.authenticate().pipe(
          take(1),
          map(error => {
            console.error({ error });
            if (error) {
              return false;
            }
            return true;
          }),
          catchError(error => {
            console.error({ error });
            return of(false);
          })
        )
      ),
      tap(biometricAuthentication => console.log({ biometricAuthentication })),
      mergeMap(biometricAuthentication =>
        biometricAuthentication
          ? mobileAuthService.getTokenSilently().pipe(
              take(1),
              retry({
                count: 10,
                delay: (_, retryCount) => timer(retryCount * 1000),
              }),
              catchError(() => of(null))
            )
          : mobileAuthService.logOut().pipe(
              take(1),
              map(() => null)
            )
      ),
      mergeMap(token =>
        token
          ? mobileAuthService.getAuth0User().pipe(
              take(1),
              map(user => ({ user, token }))
            )
          : of(null)
      ),
      map(authInfo => {
        if (authInfo) {
          const { token, user } = authInfo;
          store.dispatch(new LogIn({ token, user: AuthUser.fromAuth0(user) }));
          return new LaunchStartFlow();
        }
        return new AuthorizeUser();
      })
    );
  },
  { functional: true }
);

export const setBiometricStatusOnAndContinue$ = createEffect(
  () => {
    const actions$ = inject(Actions);
    const store = inject(Store<AppState>);
    return actions$.pipe(
      ofType(BiometricsActions.setBiometricsOnAndContinue),
      tap(() =>
        store.dispatch(BiometricsActions.completedBiometricsOnRegister())
      ),
      map(() => BiometricsActions.setBiometricSecurityOn())
    );
  },
  { functional: true }
);

export const setBiometricStatusOn$ = createEffect(
  () => {
    const actions$ = inject(Actions);
    const biometricService = inject(BiometricsService);
    const store = inject(Store<AppState>);

    return actions$.pipe(
      ofType(BiometricsActions.setBiometricSecurityOn),
      tap(() =>
        store.dispatch(
          BiometricsActions.gettingBiometrics({ gettingBiometrics: true })
        )
      ),
      mergeMap(() =>
        biometricService.checkBiometrics().pipe(
          map(result => {
            console.log('reuslt', result);
            if (result.isAvailable) {
              return BiometricsActions.authenticateSetup();
            }
            return BiometricsActions.setBiometricSecurity({
              biometricSecurity: BiometricSecurityType.unavailable,
            });
          }),
          catchError(() => {
            return of(
              BiometricsActions.setBiometricSecurity({
                biometricSecurity: BiometricSecurityType.unavailable,
              })
            );
          }),
          tap(() =>
            store.dispatch(
              BiometricsActions.gettingBiometrics({ gettingBiometrics: false })
            )
          )
        )
      )
    );
  },
  { functional: true }
);

export const authenticateSetup$ = createEffect(
  () => {
    const actions$ = inject(Actions);
    const service = inject(BiometricsService);
    const store = inject(Store<AppState>);
    return actions$.pipe(
      ofType(BiometricsActions.authenticateSetup),
      tap(() =>
        store.dispatch(
          BiometricsActions.gettingBiometrics({ gettingBiometrics: true })
        )
      ),
      mergeMap(() =>
        service.authenticate().pipe(
          take(1),
          map(error => {
            console.error({ error });
            if (error) {
              return false;
            }
            return true;
          }),
          catchError(error => {
            console.error({ error });
            return of(false);
          })
        )
      ),
      mergeMap(success => {
        console.log({ success });
        return of(
          BiometricsActions.setBiometricSecurity({
            biometricSecurity: success
              ? BiometricSecurityType.on
              : BiometricSecurityType.off,
          })
        );
      }),
      tap(() =>
        store.dispatch(
          BiometricsActions.gettingBiometrics({ gettingBiometrics: false })
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const saveBiometricConfig$ = createEffect(
  () => {
    const actions$ = inject(Actions);
    const preferencesService = inject(PreferencesService);
    return actions$.pipe(
      ofType(BiometricsActions.setBiometricSecurity),
      mergeMap(({ biometricSecurity }) => {
        return preferencesService
          .setBiometricsPreferences(biometricSecurity)
          .pipe(map(() => BiometricsActions.saveBiometricSecurity()));
      })
    );
  },
  { functional: true }
);

export const authenticate$ = createEffect(
  () => {
    const actions$ = inject(Actions);
    const service = inject(BiometricsService);
    const store = inject(Store<AppState>);
    return actions$.pipe(
      ofType(BiometricsActions.authenticate),
      mergeMap(() => store.select(biometricsSecuritySelector).pipe(take(1))),
      mergeMap(biometricSecurity =>
        biometricSecurity === BiometricSecurityType.on
          ? service.authenticate().pipe(
              take(1),
              map(() => new RefreshTokenRequired()),
              catchError(() => {
                return of(new LogOut());
              })
            )
          : of(new LogOut())
      )
    );
  },
  {
    functional: true,
  }
);

export const LogOut$ = createEffect(
  () =>
    inject(Actions).pipe(
      ofType<LogOut>(AuthActionsTypes.Logout),
      map(() => BiometricsActions.clear())
    ),
  { functional: true }
);
