export enum MobileAuthRoutes {
  root = 'auth',
  main = '',
  authorize = 'authorize',
}

export function getAuthRoute(route: MobileAuthRoutes): string {
  return (
    '/' + (route.length > 0 ? MobileAuthRoutes.root + '/' + route : MobileAuthRoutes.root)
  );
}
