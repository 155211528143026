import { MyQueryParamsModel } from 'src/app/core/models/my-query-params.model';

export class MobileRoutesHelper {
  static readonly separatorPath = '/';

  public static getParams(withOrigin = true, url?: string): MyQueryParamsModel {
    const queryParams = {};
    let urlParams: URLSearchParams;
    if (!url) {
      urlParams = new URLSearchParams(window.location.search);
    }

    if (url) {
      urlParams = new URLSearchParams(url);
    }

    const invitation = urlParams.get('invitation');
    const from = urlParams.get('from');
    const origin = urlParams.get('origin');
    const recordSubtype = urlParams.get('recordSubtype');
    const recordSubtypeOther = urlParams.get('recordSubtypeOther');
    const itemId = urlParams.get('itemId');
    const redirectTo = urlParams.get('redirectTo');
    // tslint:disable-next-line:variable-name
    const product_tour_id = urlParams.get('product_tour_id');
    if (!!invitation && invitation.length > 0) {
      // tslint:disable-next-line
      queryParams['invitation'] = invitation;
    }
    if (!!from && from.length > 0) {
      // tslint:disable-next-line
      queryParams['from'] = from;
    }
    if (!!origin && origin.length > 0 && withOrigin) {
      // tslint:disable-next-line
      queryParams['origin'] = origin;
    } else if (withOrigin) {
      // tslint:disable-next-line
      queryParams['origin'] = window.location.pathname;
    }
    if (!!recordSubtype && recordSubtype.length > 0) {
      // tslint:disable-next-line
      queryParams['recordSubtype'] = recordSubtype;
    }
    if (!!recordSubtypeOther && recordSubtypeOther.length > 0) {
      // tslint:disable-next-line
      queryParams['recordSubtypeOther'] = recordSubtypeOther;
    }
    if (!!itemId && itemId.length > 0) {
      // tslint:disable-next-line
      queryParams['itemId'] = itemId;
    }
    if (!!redirectTo && redirectTo.length > 0) {
      // tslint:disable-next-line
      queryParams['redirectTo'] = redirectTo;
    }
    if (!!product_tour_id && product_tour_id.length > 0) {
      // tslint:disable-next-line
      queryParams['product_tour_id'] = product_tour_id;
    }
    return queryParams;
  }
}
