import { Injectable } from '@angular/core';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { dictionarySelector } from '../../dictionary/store/dictionary.selectors';
import { takeWhile, tap } from 'rxjs';
import { Dictionary } from '../../dictionary/models/dictionary.model';
import { activeRecordTypeSelector } from '../../records/store/records-list.selectors';
import { RecordType } from '../../records/models/record-type.model';
import {environment} from '../../../environments/environment';

@Injectable()
export class SdPageTitleStrategy extends TitleStrategy {
  constructor(
    private readonly titleService: Title,
    private readonly store: Store<AppState>
  ) {
    super();
  }

  override updateTitle(snapshot: RouterStateSnapshot) {
    const title = this.buildTitle(snapshot);
    if (!title) {
      this.titleService.setTitle(environment.defaultPageTitle ?? 'SideDrawer');
      return;
    }
    if (title === 'activeRecordTypeName') {
      this.store
        .pipe(
          select(activeRecordTypeSelector),
          tap(recordType => {
            if (!recordType) {
              this.titleService.setTitle(environment.defaultPageTitle ?? 'SideDrawer');
              return;
            }
            this.titleService.setTitle(
              recordType?.displayValue[0]?.value ?? environment.defaultPageTitle ?? 'SideDrawer'
            );
          }),
          takeWhile((recordType: RecordType) => !recordType)
        )
        .subscribe();
      return;
    }
    this.store
      .pipe(
        select(dictionarySelector),
        tap((dictionary: Dictionary) => {
          if (!dictionary) {
            this.titleService.setTitle(environment.defaultPageTitle ?? 'SideDrawer');
            return;
          }
          this.titleService.setTitle(dictionary?.[title] ?? title);
        }),
        takeWhile((dictionary: Dictionary) => !dictionary)
      )
      .subscribe();
  }
}
