import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import {
  featureName as startFlowFeatureName,
  startFlowReducer,
} from '../../../start-flow/store/start-flow.reducer';
import { EffectsModule } from '@ngrx/effects';

import {
  accountReducer,
  featureName as accountFeatureName,
} from '../../../account/store/account.reducer';
import { AccountEffects } from '../../../account/store/account.effects';
import { MatDialogModule } from '@angular/material/dialog';
import { DictionaryModule } from 'src/app/dictionary/dictionary.module';
import { SidedrawerNetworksModule } from 'src/app/sidedrawer-networks/sidedrawer-networks.module';
import { MobileStartFlowEffects } from './store/mobile-star-flow.effects';
import { MobileCoreModule } from '../core/mobile-core.module';
import * as MobileDictionaryEffects from '../dictionary/store/mobile-dictionary.effects';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(startFlowFeatureName, startFlowReducer),
    StoreModule.forFeature(accountFeatureName, accountReducer),
    EffectsModule.forFeature([
      MobileStartFlowEffects,
      AccountEffects,
      MobileDictionaryEffects,
    ]),
    DictionaryModule,
    SidedrawerNetworksModule,
    MatDialogModule,
    MobileCoreModule,
  ],
})
export class MobileStartFlowModule {}
