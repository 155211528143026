import { Actions, createEffect, ofType } from '@ngrx/effects';

import { inject } from '@angular/core';
import { VersionReviewService } from '../services/version-review.service';
import { exhaustMap, from, map, switchMap, tap } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/reducers';
import { selectMinVersion, VersionReviewActions } from './version-review.store';
import { Device } from '@capacitor/device';
import { take } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { MobileVersionReviewRoutesEnum } from '../routes/mobile-version-review-routes.enum';
import { Router } from '@angular/router';
import { BiometricsActions } from '../../biometrics/store/biometrics.store';

export const getVersionParameters$ = createEffect(
  () => {
    const actions$ = inject(Actions);
    const versionReviewService = inject(VersionReviewService);
    const store = inject(Store<AppState>);

    return actions$.pipe(
      ofType(VersionReviewActions.requestVersionParameters),
      tap(() =>
        store.dispatch(
          VersionReviewActions.requestingVersionParameters({
            requestingVersionParameters: true,
          })
        )
      ),
      exhaustMap(() =>
        versionReviewService.getLatestVersions().pipe(
          map(({ content }) =>
            VersionReviewActions.setVersionParameters({
              versionRequirements: content,
            })
          )
        )
      )
    );
  },
  { functional: true }
);

export const setVersionParameters$ = createEffect(
  () => {
    const actions$ = inject(Actions);
    const store = inject(Store<AppState>);
    const router = inject(Router);
    return actions$.pipe(
      ofType(VersionReviewActions.setVersionParameters),
      switchMap(() => from(Device.getInfo()).pipe(take(1))),
      tap(info => {
        const [mayor, minor, fix] = environment.version.toString().split('.');
        const myCompleteVersionNumber =
          parseInt(mayor) * 1000000 + parseInt(minor) * 1000 + parseInt(fix);
        const minimumVersion = store.selectSignal(
          selectMinVersion(info.operatingSystem)
        )();
        if (myCompleteVersionNumber < minimumVersion) {
          router.navigateByUrl(MobileVersionReviewRoutesEnum.root);
          return;
        }
        store.dispatch(BiometricsActions.initializeBiometricsStore());
      })
    );
  },
  {
    functional: true,
    dispatch: false,
  }
);

// export const reviseDictionary$ = createEffect(
//   () => {
//     const actions$ = inject(Actions);
//     const store = inject(Store<AppState>);
//     const dictionaryService = inject(DictionaryService);
//     const versionReviewService = inject(VersionReviewService)

//     const defaultLang='en-us'
//   },{dispatch:false}

// )
