import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MobileDictionaryActions } from './mobile-dictionary.actions';
import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@auth0/auth0-angular';
import { DictionaryService } from '../../../../dictionary/services/dictionary.service';
import { catchError, map, tap } from 'rxjs/operators';
import {
  GettingDictionaryChanges,
  LanguagesLoaded,
  LocaleDefaultChange,
} from '../../../../dictionary/store/dictionary.actions';
import { exhaustMap, from, of } from 'rxjs';
import { Language } from '../../../../dictionary/models/language.model';
import { DictionaryHelper } from '../../../../dictionary/helper/dictionary.helper';
import { Router } from '@angular/router';
import { CoreRoutes } from '../../../../core/routes/core.routes';
import { Device } from '@capacitor/device';

export const requestDictionary$ = createEffect(
  () => {
    const actions$ = inject(Actions);
    const store$ = inject(Store<AppState>);
    const service = inject(DictionaryService);
    const router = inject(Router);
    return actions$.pipe(
      ofType(MobileDictionaryActions.dictionaryRequested),
      tap(() =>
        store$.dispatch(
          new GettingDictionaryChanges({ gettingDictionary: true })
        )
      ),
      exhaustMap(() =>
        service
          .getLanguages()
          .pipe(
            catchError(() =>
              from(
                router.navigate([
                  `/${CoreRoutes.root}/${CoreRoutes.error}/true`,
                ])
              )
            )
          )
      ),
      exhaustMap((languages: Language[]) => {
        const localeIdSaved = localStorage.getItem('localeId');
        if (localeIdSaved) {
          return of({ localeId: localeIdSaved, languages });
        }
        return from(Device.getLanguageCode()).pipe(
          map(({ value }) => ({
            localeId: Language.getLocalesByLanguageCode(languages, value)
              .localeId,
            languages,
          }))
        );
      }),
      exhaustMap(({ localeId, languages }) =>
        service.getDictionary(localeId).pipe(
          map(dictionary => {
            store$.dispatch(
              new GettingDictionaryChanges({ gettingDictionary: false })
            );
            if (languages) {
              store$.dispatch(new LanguagesLoaded({ languages }));
              store$.dispatch(
                new LocaleDefaultChange({
                  data: Language.getLocaleByLocaleId(languages, localeId),
                })
              );
            }
            if (dictionary) {
              DictionaryHelper.loadDictionaryStore(dictionary, store$, true);
              return;
            }
            router.navigate([`/${CoreRoutes.root}/${CoreRoutes.error}/true`]);
          }),
          catchError(() =>
            from(
              router.navigate([`/${CoreRoutes.root}/${CoreRoutes.error}/true`])
            )
          )
        )
      )
    );
  },
  { functional: true, dispatch: false }
);
