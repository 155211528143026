import { Injectable, NgZone } from '@angular/core';
import { User as Auth0User } from '@auth0/auth0-spa-js';
import { AppState, AuthService } from '@auth0/auth0-angular';
import { EMPTY, forkJoin, from, Observable, of, switchMap } from 'rxjs';
import { catchError, mergeMap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Browser } from '@capacitor/browser';
import { App } from '@capacitor/app';
import { callbackUri } from 'src/app/clients/mobile/mobile-app.module';
import { Router } from '@angular/router';
import { MobileRoutesHelper } from '../../core/helpers/mobile-routes.helper';
import { environment } from 'src/environments/environment';
import { AuthUser } from '../../../../auth/models/auth-user.model';
import { LogIn } from 'src/app/auth/store/auth.actions';
import { PreferencesService } from '../../biometrics/services/preferences.service';
import { Capacitor } from '@capacitor/core';

@Injectable()
export class MobileAuthService {
  constructor(
    private readonly store: Store<AppState>,
    private readonly router: Router,
    private readonly zone: NgZone,
    private readonly auth0Service: AuthService,
    private readonly preferencesService: PreferencesService
  ) {
    App.addListener('appUrlOpen', ({ url }) => {
      console.log('appUrlOpen', url);
      this.zone.run(() => {
        if (url?.startsWith(callbackUri)) {
          if (
            url.includes('state=') &&
            (url.includes('error=') || url.includes('code='))
          ) {
            this.auth0Service
              .handleRedirectCallback(url)
              .pipe(
                mergeMap(() => {
                  console.log('handleRedirectCallback subscribe');
                  forkJoin([
                    this.getAuth0User().pipe(take(1)),
                    this.auth0Service.isAuthenticated$.pipe(take(1)),
                    this.getTokenSilently().pipe(
                      take(1),
                      catchError(() => {
                        return of(null);
                      })
                    ),
                  ])
                    .pipe(
                      mergeMap(([user, isAuthenticated, token]) => {
                        console.log(
                          'handleRedirectCallback forkJoin subscribe'
                        );
                        if (isAuthenticated) {
                          this.store.dispatch(
                            new LogIn({
                              token,
                              user: AuthUser.fromAuth0(user),
                            })
                          );
                          this.redirect(url);
                          return Browser.close();
                        }
                        return this.loginWithRedirect();
                      })
                    )
                    .subscribe();
                  return Browser.close();
                }),
                catchError(error => {
                  console.log('The error is ', error);
                  return EMPTY;
                })
              )
              .subscribe();
          } else {
            Browser.close();
          }
        }
      });
    });
  }

  private redirect(url: string) {
    const urlParams = new URLSearchParams(
      url //window.location.search
    );
    const origin = urlParams.get('origin');
    const queryParams = MobileRoutesHelper.getParams(false, url);
    const urlFor =
      !!origin &&
      origin !== '/' &&
      origin !== '/auth/authorize' &&
      !origin.includes('/core/error')
        ? origin.split('?')[0]
        : '/core/home/';

    const awaitFunction = async () => {
      await this.router.navigate([urlFor], {
        queryParams,
      });
    };
    awaitFunction();
  }

  getAuth0User(): Observable<Auth0User> {
    return this.auth0Service.user$;
  }

  getTokenSilently(): Observable<string> {
    return this.auth0Service.getAccessTokenSilently({ cacheMode: 'off' });
  }

  logOut(): Observable<void> {
    return this.auth0Service
      .logout({
        clientId: environment.clientID,
        openUrl: async url => {
          console.log(url);
          await Browser.open({ url, windowName: '_self' });
          await Browser.close();
        },
      })
      .pipe(
        catchError(logOutError => {
          console.warn(logOutError);
          return of(logOutError);
        }),
        switchMap(() =>
          this.preferencesService.setLogOut().pipe(
            catchError(preferencesServiceError => {
              console.warn(preferencesServiceError);
              return of(preferencesServiceError);
            })
          )
        ),
        switchMap(() =>
          from(this.router.navigate([`/`])).pipe(
            catchError(navigateError => {
              console.warn(navigateError);
              return of(navigateError);
            })
          )
        ),
        // switchMap(() =>
        //   Capacitor.getPlatform() === 'android'
        //     ? from(App.exitApp()).pipe(
        //         catchError(exitAppError => {
        //           console.warn(exitAppError);
        //           return of(exitAppError);
        //         })
        //       )
        //     : of(location.reload())
        // ),
        switchMap(() =>
          of(location.reload())
        ),
      );
  }

  loginWithRedirect(): Observable<void> {
    return this.auth0Service.loginWithRedirect({
      async openUrl(url: string) {
        await Browser.open({ url, windowName: '_self' });
      },
    });
  }
}
