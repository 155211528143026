//share-extension-data-plugin.service.ts
import { Injectable } from '@angular/core';
import { Capacitor, registerPlugin } from '@capacitor/core';

const _pluginName: string = 'ShareExtensionDataPlugin';

export class ShareData {
  items: ShareDataItem[] = [];
}
export class ShareDataItem {
  fileName: string = '';
  type: string = '';
  url: string = '';
  webPath: string = '';
  fileSize: string = '';
}

export interface ShareExtensionDataPlugin {
  clear(): Promise<void>;
  read(): Promise<ShareData>;
}
const ShareExtensionDataPlugin =
  registerPlugin<ShareExtensionDataPlugin>(_pluginName);

@Injectable()
export class IosShareExtensionDataPluginService {
  async clear(): Promise<void> {
    if (Capacitor.isPluginAvailable(_pluginName)) {
      await ShareExtensionDataPlugin.clear();
    }
  }

  async read(): Promise<ShareDataItem[] | null> {
    console.log({iosShareExtensionAvailable: Capacitor.isPluginAvailable(_pluginName)});
    try {
      if (Capacitor.isPluginAvailable(_pluginName)) {
        return (await ShareExtensionDataPlugin.read())?.items ?? null;
      }
    } catch (e) {
      console.error(e);
      return null;
    }
  }
}
