import {
  ChangeDetectionStrategy,
  Component,
  NgZone,
  OnInit,
} from '@angular/core';
import { Platform } from '@ionic/angular';
import { Actions } from '@ngrx/effects';
import { App } from '@capacitor/app';
import { environment } from '../../../environments/environment';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { BiometricsActions } from './biometrics/store/biometrics.store';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CoreRoutes } from '../../core/routes/core.routes';
import { FilesRoutes } from '../../files/routes/files.routes';
import {
  sharedFilesAllSelector,
  ShareFileWithSdActions,
} from './files/store/share-file-with-sd.store';
import { AndroidShareSheetPluginService } from './files/services/android-share-sheet-plugin.service';
import { IosShareExtensionDataPluginService } from './files/services/ios-share-sheet-plugin.service';
import { Preferences } from '@capacitor/preferences';
import {
  StartInactivityTime,
  StopInactivityTime,
} from '../../auth/store/auth.actions';

@Component({
  selector: 'app-mobile-root',
  template: `
    <ion-app>
      <ion-content>
        <ion-router-outlet />
      </ion-content>
    </ion-app>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  // providers: [IonRouterOutlet],
  providers: [
    IosShareExtensionDataPluginService,
    AndroidShareSheetPluginService,
  ],
})
export class MobileAppComponent implements OnInit {
  inactivityTimeStamp: number;
  androidShareAFileListener$ = this.androidShareSheetPluginService.watch().pipe(
    tap(androidShareData => {
      try {
        if (androidShareData?.length > 0) {
          this.store.dispatch(
            ShareFileWithSdActions.sharedFilesReceived({
              shareFiles: androidShareData,
            })
          );
          Preferences.remove({ key: 'shareFiles' }).then();
        }
      } catch (e) {
        console.error(e);
      }
    })
  );
  constructor(
    private readonly platform: Platform,
    private readonly actions: Actions,
    private readonly store: Store<AppState>,
    private readonly iosShareExtensionDataPluginService: IosShareExtensionDataPluginService,
    private readonly androidShareSheetPluginService: AndroidShareSheetPluginService,
    private readonly router: Router,
    private readonly ngZone: NgZone
  ) {
    //this.actions.pipe(tap(action => console.log(action))).subscribe();
    this.androidShareAFileListener$.subscribe();
    App.addListener('appStateChange', ({ isActive }) => {
      if (!isActive) {
        this.inactivityTimeStamp = Date.now();
        this.store.dispatch(new StopInactivityTime());

        this.store.dispatch(ShareFileWithSdActions.resetState());
        return;
      }
      if (this.inactivityTimeStamp) {
        this.store.dispatch(new StartInactivityTime());
        const millisecondsPaused = Date.now() - this.inactivityTimeStamp;
        this.iosShareExtensionDataPluginService.read().then(iosShareData => {
          if (iosShareData?.length > 0) {
            this.store.dispatch(
              ShareFileWithSdActions.sharedFilesReceived({
                shareFiles: [
                  ...iosShareData?.map(sharedFile => ({
                    fileName: sharedFile.fileName,
                    fileSize: sharedFile.fileSize,
                    uri: sharedFile.url,
                  })),
                ],
              })
            );
            this.iosShareExtensionDataPluginService.clear();
          }
          if (this.store.selectSignal(sharedFilesAllSelector)()?.length > 0) {
            this.ngZone.run(() => {
              this.router.navigateByUrl(
                `/${CoreRoutes.root}/${FilesRoutes.shareWithSd}`
              );
            });
          }
        });
        this.inactivityTimeStamp = null;
        if (environment.appPauseApp * 1000 < millisecondsPaused) {
          this.store.dispatch(BiometricsActions.authenticate());
        }
      }
    });
  }

  ngOnInit(): void {
    this.platform.backButton.subscribeWithPriority(9999, () => {
      console.log(this.store);
    });
  }
}
