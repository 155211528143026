import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, from } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LatestVersionsResponse } from '../models/LastestVersionsResponse';
import { map } from 'rxjs/operators';
import { Device } from '@capacitor/device';
import { Preferences } from '@capacitor/preferences';

@Injectable()
export class VersionReviewService {
  private readonly http = inject(HttpClient);

  lastLang = 'lastLang';
  getLatestVersions(): Observable<LatestVersionsResponse> {
    return this.http.get<LatestVersionsResponse>(
      environment.configApi + 'content/collections/appversions/locale/-'
    );
  }
  getPreferenceLanguage(): Observable<string> {
    return from(Preferences.get({ key: this.lastLang })).pipe(
      map(({ value }) => value)
    );
  }
  getDeviceLanguage(): Observable<string> {
    return from(Device.getLanguageCode()).pipe(map(({ value }) => value));
  }
}
